
// Content styles
.block-text,
.biography {
	line-height: 1.7;
	
	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;

		&.intro {
			padding: $block-padding 0;
			border-bottom: 1px dashed $color-text;
			border-top: 1px dashed $color-text;
			font-family: $font-family-sub;
			font-size: $h5;
			line-height: 1.5;
			
			@media #{$mobileXL} {
				font-size: $h4;
			}
		}

		a {
			font-weight: $font-bold;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	li {

		a {
			font-weight: $font-bold;

			&:hover {
				text-decoration: underline;
			}
		}
	}


	// reset any <ul> that will appear in a template here
	// maintain vertical rhythm with paragraph margin
	ul[class] {
		@extend %reset-ul;
		margin: 0 0 $paragraph-margin;
	}
	
	// New default <ul> lists
	ul {
		@extend %default-ul;

		li {
			margin-left: 1rem;
		}
	}
	
	// New default <ol> lists
	ol {
		@extend %default-ol;
		
		li {
			margin-left: 1rem;
		}
	}
}

img {
	max-width:100%;
}

.section-services_for_business {

	.block-text,
	.biography {

		p {

			&.intro {
				color: $color-blue;
			}	
		}
	}
}

.section-services_for_individuals { 

	.block-text,
	.biography {

		p {

			&.intro {
				color: $color-green;
			}	
		}
	}
}